<template>
  <v-container class="pa-0">
    <v-navigation-drawer absolute clipped right v-model="filterDrawer">
      <v-list dense>
        <v-list-item v-if="activeFilter">
          <v-list-item-content>
            <v-btn
              text
              color="primary"
              @click="(onlyFree = false), (selected = [])"
              >{{ $t("vod.removeAllFilters") }}</v-btn
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("vod.restrict") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content style="height: auto">
            <v-checkbox
              color="primary"
              hide-details
              v-model="onlyFree"
              :label="$t('vod.onlyFree')"
            ></v-checkbox>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>{{ $t("vod.genre") }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-for="(genre, genreIndex) in allGenres" :key="genreIndex">
          <v-list-item-content style="height: auto">
            <v-checkbox
              color="primary"
              hide-details
              v-model="selected"
              :value="genre.genre_id"
              :label="genre.title"
            ></v-checkbox>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <div class="ma-0" row wrap>
      <v-card flat tile>
        <v-container>
          <v-flex xs12 v-if="activeFilter">
            <div v-if="allGenres.length > 0">
              <v-chip
                outlined
                class="mr-2 mb-2"
                v-if="onlyFree"
                close
                @click:close="onlyFree = false"
                >{{ $t("vod.onlyFree") }}</v-chip
              >
              <v-chip
                outlined
                class="mr-2 mb-2"
                v-for="(id, index) in selected"
                close
                :key="index"
                @click:close="closeGenreFilter({ id })"
                >{{ getGenreTitle({ id }) }}</v-chip
              >
            </div>
          </v-flex>
          <v-flex xs12>
            <v-text-field
              :label="$t('vod.search')"
              v-model="searchTerm"
              append-icon="mdi-magnify"
              hide-details
              @click:append="doSearch"
              @keydown.native.enter="doSearch"
              clearable
            ></v-text-field>
          </v-flex>
        </v-container>
      </v-card>
      <movie-list :status="statusMovies" :items="allMovies.movies"></movie-list>
      <v-layout
        v-if="allMovies.total > 0 && statusMovies === 'success'"
        row
        wrap
        align-center
      >
        <v-flex xs12 class="text-center mt-2">
          <v-btn color="primary" text :loading="loading" :disabled="loading">
            <v-icon @click="initData" class="mr-2">mdi-refresh</v-icon>
            {{ $t("vod.noDataAvailable") }}
          </v-btn>
        </v-flex>
      </v-layout>
      <!--v-flex xs6 sm3 xl2 v-for="(movie, index) in allMovies.movies" :key="index">-->
      <!--<movie-card :movie="movie" :to="getMovieRoute({id: movie.item_id},{protectedMovie: movie.protected})"></movie-card>-->
      <!--</v-flex>-->
    </div>
    <div v-if="allMovies.total > moviesPerPage" class="text-center">
      <v-pagination :length="totalPages" v-model="currentPage"></v-pagination>
    </div>
  </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from "vuex";
import filter from "lodash/filter";
import indexOf from "lodash/indexOf";
import isString from "lodash/isString";
import MovieCard from "@/components/vod/MovieCard";
import MovieList from "@/components/vod/MovieList";

export default {
  data() {
    return {
      searchTerm: "",
      filterDrawer: false,
      onlyFree: false,
      selected: [],
      genreChips: [],
      currentPage: 1,
      moviesPerPage: 48,
    };
  },
  components: {
    MovieCard,
    MovieList,
  },
  computed: {
    ...mapState({
      allMovies: ({ vod }) => vod.allMovies,
      statusMovies: ({ vod }) => vod.statusMovies,
      authResult: ({ session }) => session.authResult,
      showFilter: ({ vod }) => vod.showFilter,
      allGenres: ({ vod }) => vod.allGenres,
      statusGenres: ({ vod }) => vod.statusGenres,
    }),
    search() {
      return isString(this.searchTerm) && this.searchTerm.length > 0
        ? this.searchTerm
        : undefined;
    },
    activeFilter() {
      return this.onlyFree || this.selected.length > 0;
    },
    totalPages() {
      return Math.ceil(this.allMovies.total / this.moviesPerPage);
    },
    base64filter() {
      return btoa(JSON.stringify(this.selected));
    },
    loading() {
      return (
        this.statusGenres === "fetching" || this.statusMovies === "fetching"
      );
    },
  },
  methods: {
    ...mapActions("vod", ["getMovies", "setShowFilter", "getGenres"]),
    ...mapMutations({
      setLoading: "SET_LOADING",
    }),
    closeGenreFilter({ id }) {
      this.selected.splice(indexOf(this.selected, id), 1);
      this.doFilter();
    },
    getGenreTitle({ id }) {
      return filter(this.allGenres, { genre_id: id })[0].title;
    },
    initData() {
      this.getMovies({
        view: "detail",
        genre_ids: this.selected,
        start: (this.currentPage - 1) * this.moviesPerPage,
        limit: this.moviesPerPage,
        search: this.searchTerm,
        only_non_adult: false,
        only_free_movies: this.onlyFree,
      });
    },
    doSearch() {
      this.$router.push({
        query: {
          search: this.search,
          filter: this.base64filter,
          page: 1,
        },
      });
    },
    doFilter() {
      this.$router.push({
        query: {
          filter: this.base64filter,
          search: this.search,
          page: this.currentPage,
        },
      });
    },
  },
  created() {
    const {
      filter = btoa(JSON.stringify([])),
      page = 1,
      search = "",
    } = this.$route.query;
    this.currentPage = parseInt(page);
    this.searchTerm = search;
    this.selected = [...JSON.parse(atob(filter))];
    this.getGenres();
    this.initData();
  },
  watch: {
    loading(val) {
      this.setLoading(val);
    },
    showFilter(val) {
      this.filterDrawer = val;
    },
    filterDrawer(val) {
      this.setShowFilter({ state: val });
    },
    selected() {
      this.doFilter();
    },
    currentPage(val) {
      this.$router.push({
        query: {
          filter: this.base64filter,
          page: val,
          search: this.search,
        },
      });
    },
    onlyFree() {
      this.initData();
    },
    "$route.query.search"(val) {
      this.searchTerm = val;
      this.initData();
    },
    "$route.query.page"(val = 1) {
      this.currentPage = parseInt(val);
      this.initData();
    },
    "$route.query.filter"(val = btoa(JSON.stringify([]))) {
      this.selected = [...JSON.parse(atob(val))];
      this.initData();
    },
  },
};
</script>
