<template>
  <v-card :to="to">
    <v-img
      ratio="16/9"
      :height="movieCardHeight"
      :style="
        movie.protected
          ? 'filter: blur(5px); -webkit-filter: blur(5px);-moz-filter: blur(5px)'
          : ''
      "
      :src="$config.managerMedia + movie.cover"
      :lazy-src="$config.managerMedia + movie.cover"
    ></v-img>
    <v-progress-linear
      class="mt-0 mb-2"
      height="3"
      :value="((movie.resume_time / 60) * 100) / movie.duration"
      :color="
        ((movie.resume_time / 60) * 100) / movie.duration > 0
          ? 'primary'
          : 'transparent'
      "
    ></v-progress-linear>
    <v-card-text class="pt-0">
      <div
        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
        class="text-subtitle-2"
      >
        {{ movie.title }}
      </div>
      <div
        style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis"
        class="text-caption"
      >
        <span v-for="(genre, genreIndex) in movie.genres" :key="genreIndex">
          {{
            genre.title + (genreIndex === movie.genres.length - 1 ? "" : ",")
          }}
        </span>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "MovieCard",
  props: {
    movie: {
      type: Object,
      default: () => {
        return {};
      },
    },
    to: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    movieCardHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xl":
          return 440;
        case "lg":
          return 430;
        case "md":
          return 360;
        case "sm":
          return 260;
        default:
          return 240;
      }
    },
  },
};
</script>
